<template>
  <div class="mt-7">
      <associatedOption
              :fieldAttributes='{ name: "Quick Filters",associatedOption: localOptions,value:"all","ignoreEmptyAdd":true}'
              type="combobox"
              :fieldAttrInput="{class: '',}"
              @input="setQuickFilter"

       ></associatedOption>
  </div>
</template>
<script>
  import associatedOption from "@/commonComponents/associatedOption.vue";
export default {
  components: { associatedOption },
  methods:{
    setQuickFilter(val){
           console.log(val);
      this.$emit("quick-filter-needle",val);
    }
  },
  computed: {
    localOptions: function () {
      let localOptions = [];
      localOptions.push(["all","All"]);
      var caller = this;
      this.result.ocsTypes.forEach(function (value) {
        var mapped = caller.result.additional.protocolsMapping[value];
        if(typeof mapped=="undefined"){
          mapped = value;
        }
        localOptions.push([ value,mapped ]);
      });
      return localOptions;
    }
   },
  props:  ['result']
};
</script>