<template>
  <div style="min-width:fit-content;">
    <v-chip label v-if="headerExtras" class="mr-3">
      <v-row  class="float-right">
        <v-col col="3" v-for="(value, detail) in headerExtras" :key="detail">
          {{ detail }}:
          <strong>{{ value }}</strong>
        </v-col>
      </v-row>
    </v-chip>
  </div>
</template>
<script>
export default {
  computed: {
    headerExtras() {
      let details = false;
      if (
        typeof this.result["headerExtraInfo"] != "undefined" &&
        this.result["headerExtraInfo"]["details"]
      ) {
        details = this.result["headerExtraInfo"]["details"];
      }
      return details;
    }
  },
  props: ["result"]
};
</script>
