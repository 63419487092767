<template>
  <div>
    <!-- <v-toolbar class="mb-2" :height="60" v-if="$vuetify.breakpoint.xs">
      <tableHeaderButtons
        v-if="$vuetify.breakpoint.xs"
        :currentTable="currentTable"
        :result="result"
      ></tableHeaderButtons>
      <v-card-title class="dottedTitle"
        :class="
          typeof result['explorersFilters'] != 'undefined'
            ? 'explorerTitle'
            : ''
        "
        id="tableHeader"
        >{{ pageTitle }}</v-card-title
      >
      <span
        :class="
          typeof result['explorersFilters'] != 'undefined'
            ? 'explorerTitle'
            : ''
        "
        v-if="
          typeof currentTable.showRecordCount != 'undefined' &&
          currentTable.showRecordCount === true &&
          $vuetify.breakpoint.xs
        "
        style="position: relative; top: 1px"
        >(
        <span id="tableTotalCount">{{ currentTable.dataCount }}</span> )</span
      >
    </v-toolbar> -->
    <div v-if="typeof result.formName!='undefined' && result.formName=='searchTest'" class="mt-3">
    </div>
    <v-toolbar
      :height="60"
      :class="
        typeof currentTable.customTableHeaderClass != 'undefined'
          ? currentTable.customTableHeaderClass
          : ''
      "
    >
      <tableHeaderButtons
        v-if="!$vuetify.breakpoint.xs"
        :currentTable="currentTable"
        :result="result"
      ></tableHeaderButtons>
      <!-- <span
      v-if="typeof $route.query.function!='undefined' && $route.query.function=='showsHistoryContent'"
    >
      <v-row style="width: 385px;height:67px;">
        <v-col>
      
        </v-col>
        <v-col style="position: relative;bottom: 12px;right: 50px;">
          <v-card-title>History Details</v-card-title>
        </v-col>
      </v-row>
    </span>
    <span v-else> -->
      <dynamicComponent
        v-if="typeof currentTable['customTitle'] != 'undefined'"
        :type="currentTable['customTitle']"
        :result="result"
        :additional="{ currentTable: currentTable, pageTitle: pageTitle }"
      ></dynamicComponent>
      <v-card-title
        style="min-width: 350px"
        v-else-if="!$vuetify.breakpoint.xs && pageTitle"
        :class="
          typeof result['explorersFilters'] != 'undefined'
            ? 'explorerTitle'
            : ''
        "
        id="tableHeader"
        >{{ pageTitle }}
        <span
          :class="
            typeof result['explorersFilters'] != 'undefined'
              ? 'explorerTitle'
              : ''
          "
          v-if="
            typeof currentTable.showRecordCount != 'undefined' &&
            currentTable.showRecordCount === true &&
            !$vuetify.breakpoint.xs
          "
          style="position: relative; top: 1px"
          class="ml-1"
        >
          <span id="tableTotalCount">({{ currentTable.dataCount }})</span>
        </span></v-card-title
      >

      <span>
        <span
          v-if="typeof result['headerExtraInfo'] != 'undefined'"
          class="headerExtraDetails"
        ></span>
      </span>
      <dynamicComponent
        v-if="isset(result['customAdditionalFilters'])"
        :result="result"
      ></dynamicComponent>
      <template v-else-if="typeof result['additionalFilters'] != 'undefined'">
        <dynamicComponent
          v-for="type in result['additionalFilters']"
          :key="type"
          :type="type"
          :result="result"
          :additional="{ currentTable: currentTable }"
          v-on="$listeners"
        ></dynamicComponent>
      </template>
      <v-spacer></v-spacer>

      <template v-if="typeof result['superAdminHeader'] != 'undefined'">
        <dynamicComponent
          v-for="type in result['superAdminHeader']"
          :key="type"
          :type="type"
          :result="result"
          :additional="{ currentTable: currentTable }"
          v-on="$listeners"
        ></dynamicComponent>
      </template>
      <headerExtraDetails :result="result"> </headerExtraDetails>
      <template v-if="typeof result['explorersFilters'] != 'undefined'">
        <dynamicComponent
          v-for="type in result['explorersFilters']"
          :key="type"
          :type="type"
          :result="result"
          :additional="{ currentTable: currentTable }"
          v-on="$listeners"
        ></dynamicComponent>
      </template>
      <template cols="" v-if="typeof result['explorersFilters'] == 'undefined'">
        <template v-if="quickFilter">
          <quickFilter
            v-model="quickSearch"
            :key="currentTable.id"
            :result="tableDataSet"
            v-on="$listeners"
          ></quickFilter>
        </template>
        <template v-else>
          <div :style="$vuetify.breakpoint.mdAndUp ? 'width: 30%' : ''">
            <searchInput
              v-model="localSearch"
              :key="currentTable.id"
            ></searchInput>
          </div>
        </template>
      </template>
      <div class="ml-2" v-if="currentTable.clearFilterButton">
        <primaryButton
          label="Clear Filter"
          icon="mdi-filter-remove"
          @click="localSearch = ''"
          type="button"
          color="primary"
          id="clearFilter"
          shrink="mdAndDown"
        ></primaryButton>
      </div>
    </v-toolbar>
  </div>
</template>
<script>
import dynamicComponent from "@/commonComponents/dynamicComponent.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";

import searchInput from "@/commonComponents/searchInput.vue";
import quickFilter from "@/commonComponents/quickFilter.vue";
import headerExtraDetails from "@/commonComponents/headerExtraDetails.vue";
import tableHeaderButtons from "@/commonComponents/tableHeaderButtons.vue";
//import backButton from "@/commonComponents/backButton.vue";
//import defaultAddtionalFilter from "@/commonComponents/defaultAddtionalFilter.vue";

export default {
  components: {
    primaryButton,
    searchInput,
    dynamicComponent,
    headerExtraDetails,
    quickFilter,
    tableHeaderButtons,
    //  backButton,
  },
  data: function () {
    return {
      // localSearch: this.search,
      openDialog: false,
      searchNeedle: [],
      //path: "",
    };
  },
  /*  mounted:function(){
    console.log("-->",this.localSearch  );
  },*/

  props: {
    result: Object,
    search: String,
    currentTable: Object,
    quickFilter: { type: Boolean, default: false },
    tableDataSet: Object,
  },

  created: function () {
    // let shortURL = this.currentTable.tableShortUrl;
    // shortURL = shortURL.replace("/", "");
    //  this.path = shortURL;
    this.$store.state.closeDialog = true;
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "closeDialog") {
        this.openDialog = state.dialogClose;
      } else if (mutation.type === "pagePostChanged") {
        this.localSearch = "";
        this.$emit("input", "");
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  watch: {
    "currentTable.searchValue": function (value) {
      if (this.currentTable.setSearchInUrl) {
        this.updateRoute("searchValue", value);
      }
    },
  },
  computed: {
    pageTitle() {
      let pageTitle;
      if (typeof this.currentTable.pageTitle != "undefined") {
        pageTitle = this.currentTable.pageTitle;
      } else {
        pageTitle = this.result.pageTitle;
      }
      return pageTitle;
    },
    localSearch: {
      get() {
        return this.currentTable.searchValue;
      },
      set(newVal) {
        // console.log("back home--", newVal);
        this.$set(this.currentTable, "searchValue", newVal);
      },
    },
    quickSearch: {
      get() {
        return this.currentTable.quickSearchValue;
      },
      set(newVal) {
        console.log("back home--", newVal);
        this.$set(this.currentTable, "quickSearchValue", newVal);
      },
    },
  },
  methods: {
    updateRoute(parameter, value) {
      let shortURL = this.currentTable.tableShortUrl;
      shortURL = shortURL.replace("/", "");
      let path = shortURL;
      let regexString = "(" + parameter + "=).*?(&|$)";
      const regex = new RegExp(regexString, "g");
      if (typeof value == "undefined" || value == null) {
        value = "";
      }
      if (path.match(regex) != null) {
        path = path.replace(regex, "$1" + value + "$2");
      } else {
        path = path + "&" + parameter + "=" + value;
      }
      this.$set(this.currentTable, "tableUrl", "serve.php" + path);
      this.$set(this.currentTable, "tableShortUrl", path);
      this.$store.commit("tableChanged", this.currentTable);
      if (!this.currentTable.disableUpdateUrl) {
        history.pushState({}, null, path);
      }
      //this.searchBackendTests();
    },
  },
};
</script>
<style scoped>
.dottedTitle {
  display: inline-block;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>